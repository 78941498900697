
import {
  BasicCard,
  BrandTheme,
  ButtonComponent,
  DatePicker,
  getStore,
} from '@caresend/ui-components';
import { PropType, computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'IncompleteLogin',
  components: {
    BasicCard,
    ButtonComponent,
    DatePicker,
  },
  props: {
    loading: Boolean,
    userFirstName: {
      type: String,
      required: true,
    },
    isUserThePatient: {
      type: Boolean as PropType<boolean | undefined>,
      default: undefined,
    },
    schedulingForName: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
  },
  setup() {
    const store = getStore();

    const dateOfBirth = ref<number | undefined>(undefined);

    const dateEntered = computed<boolean>(() => !!dateOfBirth.value);

    const isExact = computed<boolean>(() =>
      store.state.app.theme === BrandTheme.EXACT,
    );

    const privacyPolicyLink = computed<string>(() =>
      isExact.value
        ? 'https://www.exactsciences.com/privacy-policy'
        : 'https://www.caresend.com/privacy-policy',
    );

    return {
      dateEntered,
      dateOfBirth,
      isExact,
      privacyPolicyLink,
    };
  },
});
