
import {
  BrandTheme,
  Loading,
  LoadingState,
  LoadingStyle,
  NavBar,
  NavItem,
  TransitionFadeOutIn,
  getRoute,
  getStore,
  isExactTheme,
  useNavBarHeight,
} from '@caresend/ui-components';
import { isInEnum } from '@caresend/utils';
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from 'vue';

import { warmUpCloudFunctions } from '@/database/firebase/API';
import { showIntercomMessenger } from '@/database/intercom/methods';
import { finishSignUpRoute, loginRoute, userSettingsRoute } from '@/router/locations';
import { PasswordMode } from '@/views/signUp/CreatePassword/model';

export default defineComponent({
  name: 'App',
  components: {
    Loading,
    NavBar,
    TransitionFadeOutIn,
  },
  setup() {
    const store = getStore();
    const route = getRoute();

    const contentElement = ref<HTMLElement | null>(null);

    onMounted(() => {
      const { syncContentMarginWithNavBarHeight } = useNavBarHeight();
      syncContentMarginWithNavBarHeight(contentElement);
      warmUpCloudFunctions();
    });

    const loadingState = computed<LoadingState | null>(() =>
      store.getters['app/getLoadingState'],
    );

    const loadingMessage = computed<string | null>(() =>
      store.getters['app/getLoadingMessage'],
    );

    const isLoadingBlocking = computed<boolean>(() =>
      store.getters['app/getIsLoadingBlocking'],
    );

    const loadingStyle = computed<LoadingStyle>(() =>
      store.state.app.isInitialLoading
        ? LoadingStyle.APP_INIT
        : LoadingStyle.DEFAULT,
    );

    const isWhiteBackground = computed<boolean>(() => {
      const whiteBgMeta = route.value.meta?.whiteBackground;
      const isWhiteBg = typeof whiteBgMeta === 'function'
        ? whiteBgMeta({ store }).value
        : !!whiteBgMeta;

      return isWhiteBg;
    });

    // Set the custom background color on the <html> element to ensure it
    // completely fills the screen.
    watch(
      () => isWhiteBackground.value,
      (isWhite) => {
        if (isWhite) {
          document.documentElement.classList.add('white-bg');
        } else {
          document.documentElement.classList.remove('white-bg');
        }
      },
      { immediate: true },
    );

    const passwordNotCreated = computed<boolean>(
      () => store.state.auth.user?.passwordCreated === false,
    );

    const showCreatePassword = computed<boolean>(() =>
      !isExactTheme() && passwordNotCreated.value,
    );

    const partnerLogoUrl = computed<string | undefined>(
      () => store.state.offices.partnerInfo?.logos?.medium?.url,
    );
    const partnerName = computed<string | undefined>(() =>
      store.state.offices.partnerInfo?.name,
    );
    const partnerSlugName = computed<string | undefined>(() =>
      store.state.offices.partnerInfo?.slugName,
    );

    watch(
      partnerSlugName,
      (newSlugName) => {
        const defaultTheme = window.location.pathname.split('/')[1]
          ?.includes('exact') ? 'exact' : 'caresend';
        const themeName = newSlugName?.includes('exact') ? 'exact' : defaultTheme;

        if (themeName !== document.documentElement.getAttribute('data-theme')) {
          document.documentElement.setAttribute(
            'data-theme',
            themeName,
          );
        }

        const theme = isInEnum<BrandTheme>(themeName, BrandTheme)
          ? themeName
          : BrandTheme.CARESEND;
        store.commit('app/SET_THEME', theme);
      },
      { immediate: true },
    );

    const createPasswordRoute = finishSignUpRoute({
      query: { passwordMode: PasswordMode.INCOMPLETE_CREATE },
    });

    const navItemsForLoggedInUser = computed<NavItem[]>(() => [
      ...(showCreatePassword.value ? [{
        label: 'Create password',
        icon: 'lock',
        to: createPasswordRoute,
      }] : []),
      {
        label: 'Settings',
        icon: 'cog',
        to: userSettingsRoute(),
      },
    ]);

    return {
      contentElement,
      isLoadingBlocking,
      loadingMessage,
      loadingState,
      loadingStyle,
      loginRoute,
      navItemsForLoggedInUser,
      partnerLogoUrl,
      partnerName,
      showIntercomMessenger,
    };
  },
});
