import { RouteConfig } from 'vue-router';

import { routeNames } from '@/router/model';

const routesUser: RouteConfig[] = [
  {
    path: '/:partnerName?/user/settings',
    name: routeNames.USER_SETTINGS,
    component: () => import(
      /* webpackChunkName: 'chunk-user-settings' */
      '@/views/user/UserSettings.vue'
    ),
    meta: {
      authRequired: true,
      allowIncomplete: true,
    },
  },
];

export { routesUser };
